<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แจ้งงาน การเดินทาง(เฉพาะงบพัฒนาบุคลากร/วิจัย)</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3 pb-15">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>เรื่อง</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="topic"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="topic"
                                    maxlength="150"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ประเภทงาน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="jobType"
                                rules="required"
                            >
                              <v-select
                                  :items="jobTypeItems"
                                  v-model="jobType"
                                  item-text="type_name"
                                  item-value="id"
                                  :error-messages="errors"
                                  dense
                                  outlined
                              ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>การเบิก</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-radio-group
                                v-model="useBudget"
                                row
                            >
                                <v-radio
                                    label="เบิกค่าใช้จ่าย"
                                    value="1"
                                ></v-radio>
                                <v-radio
                                    label="ไม่เบิกค่าใช้จ่าย"
                                    value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="useBudget == 1">
                        <v-col cols="3">
                            <v-subheader>งบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-radio-group
                                v-model="budgetType"
                                row
                            >
                                <v-radio
                                    label="งบพัฒนาบุคลากร"
                                    value="1"
                                ></v-radio>
                                <v-radio
                                    label="วิจัย"
                                    value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row v-if="useBudget == 1">
                        <v-col cols="3">
                            <v-subheader>สถานที่</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-radio-group
                                v-model="placeType"
                                row
                            >
                                <v-radio
                                    label="ภายในประเทศ"
                                    value="1"
                                ></v-radio>
                                <v-radio
                                    label="ภายนอกประเทศ"
                                    value="2"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>รายชื่อผู้เดินทาง</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-list flat>
                                <v-list-item
                                    v-for="(item, i) in travelerData"
                                    :key="i"
                                    style="left: -15px"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="staff"
                                                rules="required"
                                            >
                                                <v-autocomplete
                                                    v-model="item.staff"
                                                    :items="staffItems"
                                                    item-text="firstname"
                                                    item-value="id"
                                                    outlined
                                                    dense
                                                    :error-messages="errors"
                                                    label="เลือกผู้เดินทาง"
                                                ></v-autocomplete>
                                            </validation-provider>
                                            
                                        </v-list-item-title>
                                       
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            icon
                                            color="red"
                                            @click="removeItem(index)"
                                            style="top: -10px"
                                        >
                                            <v-icon>mdi-close-box</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <div>
                                <v-btn outlined color="primary" @click="addItem()">
                                    เพิ่มผู้เดินทาง
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>จำนวนวันกิจกรรม/โครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="daysEvent"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="daysEvent"
                                    maxlength="150"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    suffix="วัน"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ออกเดินทางระหว่างวันที่</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="travelDepart"
                                        label="วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="travelDepart"
                                    locale="th-th"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="1">
                            <v-subheader>ถึงวันที่</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="travelReturn"
                                        label="วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="travelReturn"
                                    locale="th-th"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row v-if="useBudget == 1">
                        <v-col cols="3">
                            <v-subheader>รายละเอียดค่าใช้จ่าย</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row>
                                <v-col cols="4"><div class="pt-2 text-subtitle-1">1. ค่าลงทะเบียน</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="registerPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 text-subtitle-1">2. ค่าเดินทาง</div></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 pl-7 text-subtitle-1">ค่าตั๋วเครื่องบิน</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="airTicketPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                        persistent-hint
                                        hint="คำอธิบายการเบิกค่าตั๋วเครื่องบิน"
                                    >
                                        <template v-slot:append-outer>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        color="primary"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        mdi-help-circle
                                                    </v-icon>
                                                </template>
                                                <span>คำอธิบายการเบิกค่าตั๋วเครื่องบิน</span>
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 pl-7 text-subtitle-1">ค่าน้ำมัน</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="fuelPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 pl-7 text-subtitle-1">ค่าเช่ารถ</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="rentCarPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 pl-7 text-subtitle-1">ค่า Taxi</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="taxiPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 text-subtitle-1">3. ค่าเบี้ยเลี้ยง</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="allowance"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                            <v-row>
                                <v-col cols="4"><div class="pt-2 text-subtitle-1">3. ค่าที่พัก</div></v-col> 
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="hotelPrice"
                                        outlined
                                        dense
                                        suffix="บาท"
                                    ></v-text-field>
                                </v-col> 
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>รายละเอียดเพิ่มเติม</v-subheader>
                        </v-col>
                        <v-col cols="6">
                          <vue-editor v-model="description" :editor-toolbar="customToolbar" />
                          <!-- <v-textarea
                            v-model="description"
                            auto-grow
                            outlined
                            rows="8"
                          ></v-textarea> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>แนบลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="linkFile"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile2"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile3"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile4"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile5"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";
import { VueEditor } from "vue2-editor";

export default {
    components: { VueEditor },
    data: () => ({
        storeUser: null,
        topic: 'เบิกค่าใช้จ่ายการเดินทาง',
        description: '',
        linkFile: '',
        linkFile2: '',
        linkFile3: '',
        linkFile4: '',
        linkFile5: '',
        jobType: null,
        jobTypeItems: [],
        formChkErrors: false,
        menu: false,
        menu2: false,
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        useBudget: "1",
        budgetType: "1",
        placeType: "1",
        registerPrice: "",
        airTicketPrice: "",
        fuelPrice: "",
        rentCarPrice: "",
        taxiPrice: "",
        allowance: "",
        travelerData: [{
            staff: null
        }],
        staffItems: [],
        daysEvent: "",
        hotelPrice: "",
        travelDepart: new Date().toISOString().substr(0, 10),
        travelReturn: new Date().toISOString().substr(0, 10),
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getJobType()
      await this.getStaff()
      this.$store.commit('setLoading', false)
    },
    methods: {
        addItem(){
          this.travelerData.push({
            staff: null
          })
        },
        removeItem(index){
          this.travelerData.splice(index,1); 
        },
        async getJobType() {
            try {
                const res = (await Api().get("/jobroottype/2")).data;

                if (res.status == 'success') {
                    this.jobTypeItems = res.data
                    if(this.jobTypeItems && this.jobTypeItems.length > 0){
                        this.jobType = this.jobTypeItems[0].id
                    }
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getStaff() {
            try {
                const res = (await Api().get("staff")).data;

                if (res.status == 'success') {
                    this.staffItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        getTravelerData() {
            var data = []
            this.travelerData.forEach(element => {
                data.push(element.staff)
            });

            var strData = data.join()

            return strData
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                topic: this.topic,
                job_type: this.jobType,
                due_date: "",
                description: this.description,
                link_file: this.linkFile,
                link_file2: this.linkFile2,
                link_file3: this.linkFile3,
                link_file4: this.linkFile4,
                link_file5: this.linkFile5,
                request_by: this.storeUser.id,
                traveler: this.getTravelerData(),
                days_event: this.daysEvent,
                travel_depart: this.travelDepart,
                travel_return: this.travelReturn,
                use_budget: this.useBudget,
                budget_type: this.budgetType,
                place_type: this.placeType,
                register_price: this.registerPrice,
                airticket_price: this.airTicketPrice,
                fuel_price: this.fuelPrice,
                rentcar_price: this.rentCarPrice,
                taxi_price: this.taxiPrice,
                allowance: this.allowance,
                allowance_rate: 700,
                hotel_price: this.hotelPrice
            }

            console.log(req)

            // this.formChkErrors = false
            // this.formChkErrors = await this.$refs.form.validate()

            // if(this.formChkErrors){
            //   let res = (await Api().post("jobrequesttravelling", req)).data;

            //   if (res.status == 'success') {
            //       this.$store.commit('setLoading', false)
            //       await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
            //       // this.$router.push('/staff')
            //   }else{
            //       this.$store.commit('setLoading', false)
            //   }
            // }else{
            //     this.$store.commit('setLoading', false)
            // }
        }
    }
};
</script>